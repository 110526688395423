<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellPromocodeBonusInfo,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellLink
} from '@/components/table'
import { passDataToResource, promocodes } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'

export default {
  name: 'PromocodeUsages',
  components: {
    VxTable,
    VxCellPlayerAvatar,
    VxCellLink,
    VxCellPromocodeBonusInfo,
    VxCellDate
  },
  setup () {
    const resource = passDataToResource(promocodes.usages, {
      requestParams: {
        params: { include: 'player,promocode,promocode.bonus' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'promocode_id',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.promocodeDetails, { params: { id } })
        })
      },
      {
        key: 'promocode.code',
        label: 'Promocode text'
      },
      {
        key: 'promocode.max_usage_count',
        label: 'Max usage count'
      },
      {
        key: 'promocode.bonus',
        label: 'Bonus info',
        component: VxCellPromocodeBonusInfo
      },
      {
        key: 'promocode.updated_at',
        label: 'Updated at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'code',
        label: 'Promocode text'
      },
      {
        key: 'promocode_id',
        type: filterTypes.number
      }
    ]

    return {
      resource,
      filters,
      columns
    }
  }
}
</script>
